import { useQuery } from 'react-apollo';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useBooleanFeatureFlag } from '@confluence/session-data';
import { useCreateSpaceExperiment } from '@confluence/create-space/entry-points/createSpace';
import { useIsFolderEnabled } from '@confluence/folder/entry-points/useIsFolderEnabled';

import { SpaceEnabledContentTypesQuery } from '../queries/SpaceEnabledContentTypesQuery.graphql';
import type {
	SpaceEnabledContentTypesQuery as SpaceEnabledContentTypesQueryType,
	SpaceEnabledContentTypesQueryVariables,
} from '../queries/__types__/SpaceEnabledContentTypesQuery';
import { GetDefaultSpaceQuery } from '../queries/GetDefaultSpaceQuery.experimentalgraphql';
import type {
	GetDefaultSpaceQuery as GetDefaultSpaceQueryType,
	GetDefaultSpaceQueryVariables,
} from '../queries/__types__/GetDefaultSpaceQuery';

export const useSpaceEnabledContentTypes = (spaceKey: string | undefined) => {
	const isLivePagesFFEnabled = useBooleanFeatureFlag('confluence.frontend.livepages.enable');
	const { isFolderEnabled: isFolderGateEnabled } = useIsFolderEnabled();
	const { isSpaceTypesCreationFlowEnabled } = useCreateSpaceExperiment();
	const isEditFeaturesEnabled = FeatureGates.checkGate(
		'confluence_enable_edit_features_space_level',
	);

	const isFeatureWhichRequiresEditFeaturesEnabled =
		isSpaceTypesCreationFlowEnabled || isLivePagesFFEnabled || isEditFeaturesEnabled;

	const { data: defaultSpaceKeyData } = useQuery<
		GetDefaultSpaceQueryType,
		GetDefaultSpaceQueryVariables
	>(GetDefaultSpaceQuery, {
		skip: !isFeatureWhichRequiresEditFeaturesEnabled || spaceKey !== undefined,
	});

	const { data: enabledContentTypesData } = useQuery<
		SpaceEnabledContentTypesQueryType,
		SpaceEnabledContentTypesQueryVariables
	>(SpaceEnabledContentTypesQuery, {
		errorPolicy: 'all',
		skip: !isFeatureWhichRequiresEditFeaturesEnabled || (!spaceKey && !defaultSpaceKeyData),
		variables: {
			spaceKey: spaceKey || defaultSpaceKeyData?.experimentalPromotedSpaces?.nodes?.[0]?.id,
		},
	});

	const defaultReturnValue = !isSpaceTypesCreationFlowEnabled;

	const isCreateWhiteboardEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isWhiteboardsEnabled
		: defaultReturnValue;

	const isCreateBlogEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isBlogsEnabled
		: defaultReturnValue;

	const isCreateDatabaseEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isDatabasesEnabled
		: defaultReturnValue;

	const isCreateSmartLinkEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isEmbedsEnabled
		: defaultReturnValue;

	const isLivePagesEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isLivePagesEnabled
		: false;

	const isCreateFolderEnabled =
		isFolderGateEnabled &&
		(enabledContentTypesData
			? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isFoldersEnabled
			: defaultReturnValue);

	return {
		isCreateWhiteboardEnabled,
		isCreateBlogEnabled,
		isCreateDatabaseEnabled,
		isCreateSmartLinkEnabled,
		isLivePagesEnabled,
		isCreateFolderEnabled,
	};
};
